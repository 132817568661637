/* eslint-disable eqeqeq */
import { useProfile } from "Hooks/profile/useProfile";
import ShortMessage from "../Commons/ShortMessage";
import { useGetConversation } from "Hooks/chat/useGetConversation";
import TextMessage from "pages/Chat/TextMessage";

const sortArrayByMostRecentMessage = (arr) => {
  return arr?.sort((a, b) => {
    const aLatestMessage = a.messages[0].created_at;
    const bLatestMessage = b.messages[0].created_at;
    return new Date(bLatestMessage) - new Date(aLatestMessage);
  });
};

const DashMessage = () => {
  const { profileData, isProfileDataLoading, profileDataError } = useProfile();

  const myID = profileData?.data?.data?.user?.id;

  const { conversation, isConversationLoading } = useGetConversation();

  const filterUserChats = (array, userID) => {
    return array?.filter((item) => {
      const [firstParticipant, secondParticipant] = item.participants;
      return firstParticipant.id == userID || secondParticipant.id == userID;
    });
  };

  const userMessages = filterUserChats(conversation?.data, myID);

  function countFilteredMessages(data, senderId) {
    let count = 0;
  
    data?.forEach(conversation => {
      conversation.messages.forEach(message => {
        if (message.read === false && message.sender != senderId) {
          count++;
        }
      });
    });
  
    return count;
  }
  
  
const unreadMessages = countFilteredMessages(userMessages, myID)


  const sortedMessages = sortArrayByMostRecentMessage(userMessages);

  return (
    <div className="das-mess-container !bg-white" style={{ width: "100%" }}>
      <div className="mess-unread">
        <div className="mess-headline">Messages</div>
        <div className="unread-box">{unreadMessages}</div>
      </div>
      <div
        className=" overflow-y-auto max-h-[260px] !bg-white"
        style={{ width: "100%" }}
      >
        <div className="list-people-st">
          <div className="bg-white py-0 px-3 rounded">
            {sortedMessages?.map((item) => (
              <TextMessage
                key={item?.id}
                text={item?.messages[0]?.text}
                date={item?.messages[0]?.created_at}
                friend={item?.participants}
                userID={myID}
                isRead={true}
                rightSide={true}
                messages={item?.messages}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashMessage;
