import React from "react";
import Description from "components/newCommerce/typography/txtDescription";
import Header from "components/newCommerce/typography/txtHeader";
import { ModalContext } from "Context/ModalContext";
import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Fab } from "@mui/material";
import { FaWallet } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import Stacked from "components/newCommerce/shared/Stacked";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import { FormatwithComma } from "utils/commerceUtils";
import {
  Userdefaultaddress,
  User,
  createCheckout,
  createPurchase,
  Orderlist,
} from "api/commerce/Apiactions";
import useLocalstorage from "api/hooks/commerce/uselocalStorage";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Modal } from "@mui/material";

const Buyconfirmcheckout = () => {
  const [loadingorder, setLoadingorder] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [tabpayment, settabPayment] = useState(false);
  const { nextCartPopup, itemParam, detailsBuyerEdit } =
    useContext(ModalContext);
  // address-change/edit
  const changeAddress = (payload) => {
    nextCartPopup(payload);
  };
  const { getFormlist } = useLocalstorage();
  // ifa-user-detail-exist-in-local-storage-then-filter-vy-default
  const [firstDefaultUser, setFirsdtDefault] = useState([]);
  const [firstUser, setFirstuser] = useState([]);

  useEffect(() => {
    const defaultuser = JSON.parse(getFormlist)?.find(
      (user) => user.isdefault === true
    );

    defaultuser ? setFirsdtDefault([defaultuser]) : setFirsdtDefault(null);

    // get-first-user-if-no-default-exist
    const firstUserdetail = JSON.parse(getFormlist);
    let userfirst = [];
    if (!firstUserdetail) {
      userfirst = [];
    } else {
      userfirst = firstUserdetail.find((val, index) => index === 0);
      setFirstuser([userfirst]);
    }
  }, [getFormlist]);

  const confirmOrder = async (payload) => {
    // check-ifuserneeds-to-change-location
    if (!JSON.parse(getFormlist) || JSON.parse(getFormlist).length < 1) {
      window.alert("Please Change your default address!");
      return;
    }
    setSuccess(false);
    setLoading(true);
    setError(null);
    settabPayment(false);
    const formDatas = {
      shipping_address: {
        address: firstDefaultUser[0]?.del_address || firstUser[0]?.del_address,
        city: firstDefaultUser[0]?.city || firstUser[0]?.city,
        postal_code: firstDefaultUser[0]?.postal_code || "",
        country: firstDefaultUser[0]?.country || "Nigeria",
      },
      payment_method: "wallet",
      shipping_fee: "0",
    };

    setLoadingorder(true);
    try {
      const postcheckout = await createCheckout(formDatas);
      if (postcheckout) {
        toast.success(`Order Successfull`);
        console.log(postcheckout);
        if (postcheckout.data.id) {
          handleOpen();
          // send-signal-to-purchase

          let orderid = null;
          try {
            const ordersget = await Orderlist();
            if (ordersget) {
              orderid = ordersget[ordersget.length - 1].id;
            }
          } catch (err) {
            console.log(err);
            setError(err);
            setTimeout(() => {
              handleClose();
            }, 2500);
          }

          const purchase = await createPurchase(orderid);

          if (purchase.callback_url) {
            setLoading(false);
            setSuccess(true);
            settabPayment(true);
            window.open(purchase.callback_url, "_blank");
          } else {
            setError("Payment Failed with unknown error");

            setTimeout(() => {
              handleClose();
            }, 2500);
            toast.error("Payment failed");
            setLoading(false);
          }
        }
      }
    } catch (err) {
      toast.error(`Error Creating Checkout`);
      setError(err);
      handleClose();
    } finally {
      setLoadingorder(false);
    }
  };

  // getand-cal-item-totalprince-withshipping fee if exists
  const totalItemPrice = itemParam.reduce(
    (acc, item) => acc + item?.product.price * Number(item?.quantity),
    0
  );
  const totalDeliveryFee = itemParam.reduce(
    (acc, item) => acc + (item?.delivery_fee || 0),
    0
  );

  const totalAmount = totalItemPrice + totalDeliveryFee;

  return (
    <>
      <div className="flex flex-col w-full gap-y-3">
        <div className="bg-[#ffff] py-4 px-3 flex  items-center rounded-xl gap-x-3 gap-y-6 flex-wrap">
          {itemParam.map((item, index) => {
            return (
              <div
                className="w-[27rem] flex flex-row gap-x-3 gap-y-3 "
                key={index}
              >
                <div className="w-[100px] h-[100px]">
                  <img
                    className="w-full h-full rounded-lg"
                    src={item.product.product_image}
                    alt="cart-product"
                  />
                </div>
                <div className="flex flex-col items-start justify-between">
                  <Header
                    title={item.product.name}
                    fw="500"
                    fz="18px"
                    cl="black"
                  />
                  <Header
                    title={FormatwithComma(item?.product.price)
                      .toString()
                      .replace(/^/, "₦")}
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  />
                  <div className="flex items-center gap-x-2">
                    <Description
                      title={`QTY: ${item.quantity}`}
                      fw="500"
                      fz="14px"
                    />
                    {/* <Header
                      title={item.Inventory}
                      fw="500"
                      fz="18px"
                      cl="black"
                    /> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* payment-method */}
        <Header
          title="PAYMENT METHOD"
          fw="400"
          fz="18px"
          sx={{ textAlign: "left !important" }}
        />
        {/* wallet-payment or fund-transaction */}
        <div className="flex justify-between items-center p-2 rounded-lg bg-[#ffff]">
          <Stacked d="row" g=".5rem" ai="center">
            <Fab
              sx={{
                borderRadius: "5px",
                boxShadow: "none",
                background: "#4f0da3",
                padding: "2px !important",
                width: "35px",
                height: "35px",
                zIndex: "9",
              }}
            >
              <FaWallet fontSize="15px" fill="white" />
            </Fab>
            <Stacked d="column" g=".4rem" ai="start">
              <Header
                title="Pay with wallet"
                sx={{ fontSize: "15px" }}
                fw="400"
              />
              <Description title="₦0.000" sx={{ fontSize: "13px" }} />
            </Stacked>
          </Stacked>
          {/* button-fund */}
          <button className="bg-[#4f0da3] py-2 px-2 text-white rounded-lg text-[13px]">
            Fund wallet
          </button>
        </div>
        {/* DELIVERY ADDRESS */}

        <div className="flex items-center justify-between">
          <Header
            title="DELIVERY ADDRESS"
            fw="400"
            fz="18px"
            sx={{ textAlign: "left !important" }}
          />
          <button
            className="bg-[#f5f5f5] py-2 px-2 rounded-lg text-[#4f0da3] text-[13px]"
            onClick={() => changeAddress("addressSelection")}
          >
            Change
          </button>
        </div>

        {/* customer-details */}
        <div className="bg-[#ffff] flex flex-col gap-y-2 p-2 rounded-lg items-start">
          <>
            {!JSON.parse(getFormlist) || JSON.parse(getFormlist).length < 1 ? (
              <>
                <Header
                  title={`${User[0].toUpperCase()}${User.slice(1)}`}
                  cl="black"
                />
                <Description
                  fz="13px"
                  title={`${Userdefaultaddress.city || ""} ${
                    Userdefaultaddress.country
                  } `}
                />
              </>
            ) : firstUser.length > 0 && !firstDefaultUser ? (
              firstUser?.map((details, index) => {
                return (
                  <div key={index}>
                    <Header
                      title={`${details.fname[0].toUpperCase()}${details.fname.slice(
                        1
                      )}  ${details.secname[0].toUpperCase()}${details.secname.slice(
                        1
                      )}`}
                      cl="black"
                    />
                    <Description
                      fz="13px"
                      title={`${details.del_address || ""} ${details.city} ${
                        details.phone_number
                      }`}
                    />
                  </div>
                );
              })
            ) : (
              <>
                {firstDefaultUser && firstDefaultUser.length > 0
                  ? firstDefaultUser?.map((details, index) => {
                      return (
                        <div key={index}>
                          <Header
                            title={`${details?.fname[0].toUpperCase()}${details.fname.slice(
                              1
                            )}  ${details.secname[0].toUpperCase()}${details.secname.slice(
                              1
                            )}`}
                            cl="black"
                          />
                          <Description
                            fz="13px"
                            title={`${details.del_address || ""} ${
                              details.city
                            } ${details.phone_number}`}
                          />
                        </div>
                      );
                    })
                  : null}
              </>
            )}
          </>
        </div>
        {/* fee-settings */}
        <Header
          title="FEE SETTINGS"
          cl="black"
          sx={{ textAlign: "left !important" }}
        />

        {/* item-fee-settings-andcheckout-total-payment */}
        <div className="bg-[#ffff] rounded-lg flex flex-col gap-y-3 items-start p-2">
          {/* flex-item-total */}

          <React.Fragment>
            <div className="flex items-center justify-between w-full">
              <Description title="Item total" fz="13px" fw="500" cl="#000000" />
              <Description
                title={FormatwithComma(totalItemPrice)
                  .toString()
                  .replace(/^/, "₦")}
                fz="13px"
                cl={"rgba(0, 0, 0, 0.6)"}
              />
            </div>

            {/* flex-item-delivery-fee */}
            <div className="flex items-center justify-between w-full">
              <Description
                title="Delivery fee"
                fz="13px"
                fw="500"
                cl="#000000"
              />
              <Description
                title={FormatwithComma(totalDeliveryFee)
                  .toString()
                  .replace(/^/, "₦")}
                fz="13px"
                cl={"rgba(0, 0, 0, 0.6)"}
              />
            </div>

            {/* line-full-bg */}
            <div className="w-full bg-[#d9d9d9] h-[.15rem]"></div>
            {/* total-fee-inc-delivery-fee */}

            <div className="flex items-center justify-between w-full">
              <Description title="Total" fz="13px" fw="500" cl="#000000" />
              <Description
                title={FormatwithComma(totalAmount)
                  .toString()
                  .replace(/^/, "₦")}
                fz="13px"
                cl={"rgba(0, 0, 0, 0.6)"}
              />
            </div>
          </React.Fragment>
        </div>
      </div>

      <Description
        title={titleText}
        sx={{ textAlign: "left !important", visibility: "hidden !important" }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Promote Subcription"
        aria-describedby="Waitin to subscribe to a plan"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#ffff",
            paddingBlock: 4,
            paddingInline: 3,
            borderRadius: 2,
          }}
        >
          {loading ? (
            <Stacked d="column" ai="center" jc="center" g={3}>
              <CircularProgress />
              <Header
                title={`${
                  success ? "Connecting Payment..." : "Gathering Information..."
                }`}
              />
            </Stacked>
          ) : (
            <>
              {success && (
                <Stacked d="column" ai="center" jc="center" g={3}>
                  <CircularProgress />
                  <Header
                    title={`${
                      tabpayment
                        ? "Checking Payment status.."
                        : "Connecting Payment..."
                    }`}
                  />
                  {tabpayment && <Header title={"Do not Close !"} />}
                </Stacked>
              )}

              {error && (
                <Stacked d="column" ai="center" jc="center" g={3}>
                  <FaTimes fontSize={"4.2rem"} fill="red" />
                  <Header title={"Error try again"} />
                </Stacked>
              )}
            </>
          )}
        </Box>
      </Modal>

      {/* padding-top-buttn */}
      <div className="flex items-center justify-center pt-[4rem]">
        <ButtonSide
          title={"Confirm Checkout"}
          bg="#4f0da3"
          cl="#ffff"
          jc="initial"
          styles={{ paddingInline: "8rem", paddingBlock: "0.95rem" }}
          pb={".8rem"}
          start={
            loadingorder ? (
              <AiOutlineLoading3Quarters className="animate-spin mt-[0.2rem] text-[20px] mr-4 fill-[#4f0da3] stroke-[10px]" />
            ) : null
          }
          isDisabled={loadingorder ? true : false}
          click={() => confirmOrder("ordercompleted")}
        />
      </div>
    </>
  );
};

let titleText =
  "Exceptional product! Impressive quality, versatile features, and user-friendly design. A must-have for anyone seeking top-notch performance.";
export default Buyconfirmcheckout;
